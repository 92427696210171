<template>
  <!-- 菜单权限管理 -->
  <div class="paddbox autobox">
    <div>
      1111
      <el-card>
        <div class="addBtn flex">
          <div>
            <el-button
              type="primary"
              size="mini"
              @click="pullAccountVideoViewChange()"
              >同步视频到剪辑素材库</el-button
            >
          </div>
          <div>
            <el-button
              type="success"
              size="mini"
              @click="pushAccountVideoViewChange()"
              >批量推送视频</el-button
            >
          </div>
          <div>
            <el-button type="info" size="mini" @click="BatchRefreshVideo()"
              >批量刷新素材</el-button
            >
          </div>
          <div>
            <el-button type="danger" size="mini" @click="BatchDeleteVideo()"
              >批量删除素材</el-button
            >
          </div>
          <div>
            <el-date-picker
              v-model="day"
              type="date"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
          <div class="flex">
            <div>
              <el-input
                placeholder="请输入账户id"
                v-model="accountId2"
                clearable
              >
              </el-input>
            </div>
            <div>
              <el-button type="primary" @click="selectClipLibraryAllFun()"
                >搜索</el-button
              >
            </div>
          </div>
          <div></div>
        </div>

        <el-divider></el-divider>
        <!-- table部分 -->
        <div>
          <div>
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              >全选</el-checkbox
            >
          </div>
          <!-- 视频素材集合 -->
          <div class="videoListdiv">
            <el-checkbox-group
              v-model="checkedCities"
              @change="handleCheckedCitiesChange"
            >
              <div class="flex">
                <div
                  class="videoListdiv-crad"
                  v-for="(item, index) in tableData"
                >
                  <el-card>
                    <div>
                      <div>账户id：{{ item.accountId }}</div>
                      <div>素材id：{{ item.materialId }}</div>
                      <div>
                        <el-checkbox
                          style="margin-top:10px"
                          :label="item"
                          :key="item"
                        >
                          <div class="videoListdiv-title">
                            <div class="imgv">
                              <img
                                :src="item.imageUrl"
                                width="230"
                                height="360"
                              />
                            </div>

                            <br />
                            <span
                              ><el-button
                                type="primary"
                                size="mini"
                                @click="redirectUrlFun(item)"
                                >播放视频</el-button
                              ></span
                            >
                          </div>
                        </el-checkbox>
                      </div>
                    </div>
                  </el-card>
                </div>
              </div>
            </el-checkbox-group>
          </div>
        </div>
        <!-- 分页 -->
        <div class="pagination">
          <pagination
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :total="total"
            :pagesize="pagesize"
            :currentPage="currentPage"
          ></pagination>
        </div>
      </el-card>
    </div>

    <div>
      <!-- 账户拉取视频弹窗控制 -->
      <el-dialog v-model="pullAccountVideoView">
        <div>
          <el-form label-width="80px">
            <el-form-item label="账户id：">
              <el-input
                type="number"
                v-model="accountId"
                placeholder="请输入账户id"
              ></el-input>
            </el-form-item>
            <el-form-item label="素材id：">
              <div>
                <el-input
                  placeholder="请输入素材id，每行一个素材id，敲击回车换行"
                  :rows="20"
                  type="textarea"
                  v-model="desc"
                ></el-input>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="downBtn flex">
          <div>
            <el-button @click="pullAccountVideoViewChange()">取消</el-button>
          </div>
          <div>
            <el-button @click="confirmFun()" type="primary">确定</el-button>
          </div>
        </div>
      </el-dialog>

      <!-- 推送视频弹窗控制 -->
      <el-dialog v-model="pushAccountVideoView">
        <div>
          <el-form label-width="100px">
            <el-form-item label="推送账户id：">
              <div>
                <el-input
                  placeholder="请输入账户id，每行一个账户id，敲击回车换行"
                  :rows="20"
                  type="textarea"
                  v-model="tdesc"
                ></el-input>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="downBtn flex">
          <div>
            <el-button @click="pushAccountVideoViewChange()">取消</el-button>
          </div>
          <div>
            <el-button @click="pushConfirmFun()" type="primary">确定</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  syncTitleGroup,
  selectClipLibraryAll,
  batchPushVideo,
  batchDeleteClipLibrary,
  batchRefreshVideoUrl,
} from '@/request/new/videosucai'
import { ElMessage } from 'element-plus'
import { inject, reactive, toRefs, watch } from 'vue'
import { selectAccountIdIsNotExsit } from '@/request/new/videoBatchManagement'
import pagination from '@/components/pagination.vue' // 导入分页组件

export default {
  name: 'videoSucai',
  components: { pagination },
  setup() {
    const state = reactive({
      // 推送的广告主id
      tAccountId: [],
      // 全选值
      checkAll: false,
      isIndeterminate: false,
      // 被选中的
      checkedCities: [],
      // 循环取出的id
      forCheckedCities: [],
      // 账户id
      accountId: '',
      // 输入框选中的账户id
      accountId2: '',
      // 素材列表字符串
      desc: '',
      // 账户id列表字符串
      tdesc: '',
      // 推送链接
      // 素材列表
      videoList: [],
      // 表格数据
      tableData: [],

      // 账户拉取视频弹窗控制
      pullAccountVideoView: false,
      // 推送视频弹窗控制
      pushAccountVideoView: false,
      // 日期
      day: '',
      // 条数
      pagesize: 20,
      // 当前页数
      currentPage: 1,
      // 总数
      total: 100,
      // 页数 发生改变
      handleCurrentChange: (e) => {
        state.currentPage = e
        selectClipLibraryAllFun()
      },
      // 个数 发生改变
      handleSizeChange: (e) => {
        state.pagesize = e
        selectClipLibraryAllFun()
      },
      // 跳转视频播放地址
      redirectUrlFun: (item) => {
        window.open(item.videoUrl, '_blank')
      },
      // 清空数据
      deleteOldData: () => {
        state.isIndeterminate = false
        state.checkAll = false
        state.tAccountId = []
        state.checkedCities = []
        state.forCheckedCities = []
        state.videoList = []
        state.accountId = ''
        state.accountId2 = ''
        state.desc = ''
        state.tdesc = ''
      },
      // 批量推送视频的确定
      pushConfirmFun: () => {
        console.log('排查', state.checkedCities)
        if (state.checkedCities.length > 0 && state.tdesc != '') {
          state.BatchPushVideoFun()
        } else {
          ElMessage({
            type: 'error',
            message: '素材选择不能为空',
          })
        }
      },
      // 批量推送视频
      BatchPushVideoFun: () => {
        state.forCheckedData()
        state.tAccountId = state.tdesc.split(/[(\r\n)\r\n]+/)
        const datas = {
          ids: state.forCheckedCities,
          taragtAdvertiserIds: state.tAccountId,
        }
        batchPushVideo(datas).then((res) => {
          ElMessage({
            type: 'success',
            message: '推送成功',
          })
          state.pushAccountVideoViewChange()
          state.deleteOldData()
        })
      },
      // 批量刷新素材
      BatchRefreshVideo: () => {
        if (state.checkedCities.length > 0) {
          state.forCheckedData()
          const datas = {
            ids: state.forCheckedCities,
          }
          batchRefreshVideoUrl(datas).then((res) => {
            ElMessage({
              type: 'success',
              message: '刷新成功',
            })
            selectClipLibraryAllFun()
            state.deleteOldData()
          })
        } else {
          ElMessage({
            type: 'error',
            message: '素材选择不能为空',
          })
        }
      },
      // 批量删除素材
      BatchDeleteVideo: () => {
        if (state.checkedCities.length > 0) {
          state.forCheckedData()
          const datas = {
            ids: state.forCheckedCities,
          }
          batchDeleteClipLibrary(datas).then((res) => {
            ElMessage({
              type: 'success',
              message: '删除成功',
            })
            selectClipLibraryAllFun()
            state.deleteOldData()
          })
        } else {
          ElMessage({
            type: 'error',
            message: '素材选择不能为空',
          })
        }
      },

      handleCheckAllChange: (val) => {
        val == true
          ? (state.checkedCities = state.tableData)
          : (state.checkedCities = [])
        state.isIndeterminate = false
      },
      handleCheckedCitiesChange: (value) => {
        let checkedCount = value.length
        state.checkAll = checkedCount === state.tableData.length

        state.isIndeterminate =
          checkedCount > 0 && checkedCount < state.tableData.length
        state.checkedCities = value
      },

      // 把选中的素材循环取出id
      forCheckedData: () => {
        state.forCheckedCities = []
        state.checkedCities.forEach((e) => {
          state.forCheckedCities.push(e.id)
        })
      },

      // 账户拉取视频弹窗值的变化
      pushAccountVideoViewChange: () => {
        state.pushAccountVideoView = !state.pushAccountVideoView
      },
      // 账户拉取视频弹窗值的变化
      pullAccountVideoViewChange: () => {
        state.pullAccountVideoView = !state.pullAccountVideoView
      },
      // 从账户拉取视频的确定
      confirmFun: () => {
        if (state.accountId.length == 0) {
          return ElMessage({
            type: 'error',
            message: '请填写账户id',
          })
        }

        const datas = {
          advertiserId: Number(state.accountId),
        }
        console.log('state.accountId', state.accountId)
        selectAccountIdIsNotExsit(datas).then((res) => {
          if (res.code == 200) {
            if (state.desc.length == 0) {
              return ElMessage({
                type: 'error',
                message: '请填写素材id',
              })
            }
            state.videoList = state.desc.split(/[(\r\n)\r\n]+/)
            state.videoList.forEach((item, index) => {
              if (!item) {
                state.videoList.splice(index, 1)
              }
            })
            console.log('state.videoList', state.videoList)
            const datass = {
              advertiserId: state.accountId,
              materialIds: state.videoList,
            }
            syncTitleGroup(datass).then((res) => {
              state.pullAccountVideoViewChange()
              state.accountId = ''
              state.desc = ''
              state.videoList = []
              ElMessage({
                type: 'success',
                message: '同步成功，请等待',
              })
            })
          }
        })
      },
    })

    const selectClipLibraryAllFun = () => {
      console.log('state.day', state.day)
      const datas = {
        currenPage: state.currentPage,
        size: state.pagesize,
        createTime: state.day,
        accountId: state.accountId2,
      }
      selectClipLibraryAll(datas).then((res) => {
        state.currentPage = res.data.currenPage
        state.total = res.data.totalSize
        state.tableData = res.data.data
      })
    }

    selectClipLibraryAllFun()
    return {
      // 结构赋值，暴露出去就不用 state.xx
      ...toRefs(state),
      selectClipLibraryAllFun,
    }
  },
}
</script>

<style lang="scss" scoped>
.add {
  div {
    padding-right: 50px;
  }
}

.add-button-yes {
  justify-content: flex-end;
  > div {
    margin-left: 40px;
  }
}

.downBtn {
  justify-content: flex-end;
  padding: 10px 10px 0px 15px;
  > div {
    padding-left: 30px;
  }
}

.imgv {
  cursor: pointer;
}

.v-for-videoList {
  width: 270px;
  height: 480px;
  margin-right: 10px;
}

.videoListdiv {
  flex-wrap: wrap;
  overflow-y: auto;
}

// .checkbox-input {
//   background-color: initial;
//   cursor: default;
//   appearance: checkbox;
//   box-sizing: border-box;
//   padding: initial;
//   border: initial;
// }

.addBtn {
  > div {
    padding-right: 60px;
  }
}

.videoListdiv /deep/ .el-checkbox__input {
  display: flex;
}
.videoListdiv-crad {
  margin-right: 10px;
}
.videoListdiv-title {
  margin-left: 20px;
  > span {
    margin-bottom: 4px;
    display: block;
  }
}
</style>
